import { showToastError } from "./toast";
import { hideLoader } from "./loader";
import { showErrorModal } from "./alert";

// Function to convert error messages to alert messages


function isJSON(content) {

    return content.constructor === {}.constructor
}



function convertErrorsToAlerts(errors) {
    if (!isJSON(errors)) {
        return [];
    }

    let alertMessages = [];
    for (const field in errors) {
        if (Array.isArray(errors[field])) {
            const messages = errors[field].map((message) => `${field}: ${message}`);
            alertMessages = alertMessages.concat(messages);
        } else {
            alertMessages.push(`${field}:${errors[field]}`);
        }
    }
    return alertMessages;
}

function handleErrors(error) {
    hideLoader();


    //handle error

    // Convert errors to alert messages

    const errorData = error.response.data;
    if ('title' in errorData && 'body' in errorData) {
        showErrorModal(errorData.title, errorData.body)
    } else {

        const errorMessage = `${error.message}`;
        showToastError(errorMessage);
        const alertMessages = convertErrorsToAlerts(errorData);
        if (alertMessages.length > 0) {
            const alertMessage = alertMessages.join("\n");
            showToastError(alertMessage);
        }
    }

}



export default handleErrors;