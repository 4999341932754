import { removeCommasFromNumber } from "./number";
import { getElementByIdOrThrow, getAttributeOrThrow } from "./dom/getElementByIdOrThrow";
/**
 * Extracts data from a serialized form and optionally converts specified fields to integers.
 * @param {Object} options - The options object.
 * @param {Array} options.formData - The serialized form data.
 * @param {string[]} [options.intFields=[]] - The list of field names to convert to integers.
 * @param {bool} options.skipEmpty - Do not return empty fields.
 * @returns {Record<string, any>} The extracted data with optional conversion of specified fields to integers.
 */

interface Item {
    name: string;
    value: string;
}

interface GetDataParams {
    formData: Item[];
    intFields?: string[];
    skipEmpty?: boolean;
}
interface FormField {
    name: string;
    value: string;
}
// TODO MERGE THE TWO FUNCTIONS(serializeForm & getDataFromSerialization)
function serializeForm(form: HTMLFormElement): FormField[] {
    const formData = new FormData(form);//Ignores disabled fields
    const serializedArray: FormField[] = [];

    formData.forEach((value, key) => {
        serializedArray.push({
            name: key,
            value: typeof value === 'object' ? JSON.stringify(value) : value.toString()
        });

    });

    return serializedArray;
}

function getDataFromSerialization({ formData, intFields = [], skipEmpty = false }: GetDataParams): Record<string, string | number> {
    const newObj: Record<string, string | number> = {};

    for (const item of formData) {
        let itemValue: string | number = item.value;


        if (itemValue === "" && skipEmpty) {
            continue;
        }

        if (intFields.includes(item.name)) {
            itemValue = item.value === "" ? 0 : removeCommasFromNumber(item.value);
        }
        newObj[item.name] = itemValue;
    }
    console.log(newObj);

    return newObj;
}
const getCsrfToken = (): string => {
    return getAttributeOrThrow(getElementByIdOrThrow('csrf-token'), 'data-csrf-token');

}
export { getDataFromSerialization, serializeForm, getCsrfToken };
