const getElementByIdOrThrow = function (id: string): HTMLElement {
    const element = document.getElementById(id);
    if (!element) {
        throw new Error(`Element with ID "${id}" not found.`);
    }
    return element;
}
const getAttributeOrThrow = (element: HTMLElement, attr: string): string => {
    const attribute = element.getAttribute(attr);
    if (!attribute) { throw new Error(`element has no attribute ${attr}`) }
    return attribute;

}
export { getElementByIdOrThrow, getAttributeOrThrow };