import Toastify from 'toastify-js';

/**
 * Show success message
 * @param {string} message -- The message to display
 * @returns {void}
 */
function showToastSuccess(message) {
    Toastify({
        text: message,
        duration: 3000,
        close: true,

        className:
            "bg-gradient-to-r from-green-700 via-green-800 bg-green-900 rounded-3xl text-sm",
        gravity: "bottom", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();
}
/**
 * Show error message
 * @param {string} message - The error message to display
 * @returns {void}
 */
function showToastError(message) {
    Toastify({
        text: message,
        duration: 3000,
        close: true,

        className:
            "bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700  rounded-3xl text-sm",
        gravity: "bottom", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover

        onClick: function () { }, // Callback after click
    }).showToast();
}

export { showToastSuccess, showToastError }