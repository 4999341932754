
import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        popup: "rounded-3xl bg-white text-blue-700",
        title: "custom-title-class",
        // content: "custom-content-class",
        confirmButton:
            "bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-blue-600 hover:scale-95 px-4 py-2 rounded-3xl mx-2 text-white font-medium",
        cancelButton:
            "bg-gradient-to-r from-red-500 via-red-600 to-red-700  hover:bg-red-600 hover:scale-95 px-4 py-2 rounded-3xl mx-2 text-white font-medium",
    },
    buttonsStyling: false,
});
function showErrorModal(title: string, text: string) {
    return swalWithBootstrapButtons.fire({
        title: title,
        text: text,
        icon: "error",
        customClass: {
            popup: "rounded-3xl bg-white text-red-700",
            confirmButton:
                "bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-red-600 hover:scale-95 px-5 py-2 rounded-full mx-2 text-white font-medium",
        },
    });
}

function showSuccessModal(title: string, text: string) {
    return swalWithBootstrapButtons.fire({
        title: title,
        text: text,
        icon: "success",
        customClass: {
            popup: "rounded-3xl bg-white text-green-700",
            confirmButton:
                "bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-green-600 hover:scale-95 px-5 py-2 rounded-full mx-2 text-white font-medium",
        },
    });
}
function showQuestionModal(title: string, text: string, confirmButtonText: string, cancelButtonText: string) {
    return swalWithBootstrapButtons.fire({
        title: title,
        text: text,
        icon: "question",

        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: false,
    });
}


function showQuestionModalDanger(title: string, text: string, confirmButtonText: string, cancelButtonText: string) {
    return Swal.mixin({
        customClass: {
            popup: "rounded-3xl bg-white text-red-700",
            title: "custom-title-class",
            // content: "custom-content-class",
            confirmButton:
                "bg-red-600 hover:scale-95 px-4 py-2 rounded-xl mx-2 text-gray-100 font-medium",
            cancelButton:
                "bg-blue-600 hover:scale-95 px-4 py-2 rounded-xl mx-2 text-gray-100 font-medium",
        },
        buttonsStyling: false,
    }).fire({
        title: title,
        text: text,
        icon: "question",

        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: false,
    })
}

export { showErrorModal, showQuestionModal, showSuccessModal, showQuestionModalDanger }

