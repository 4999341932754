type EventType = 'click' | 'change' | 'input' | 'focus' | 'blur';
/**
 * Adds event delegation to a parent element.
 * 
 * @param {HTMLElement} parent - The parent element to which the event listener is attached.
 * @param {EventType} eventType - The type of event to listen for (e.g., 'click', 'mouseover').
 * @param {string} className - The selector for child elements that should trigger the event.
 * @param {(event: Event) => void} callback - The function to call when the event is triggered on the matched elements.
 */

function addEventDelegation(
    parent: EventTarget,
    eventType: EventType,
    className: string,
    callback: (event: Event, matchedElement: HTMLElement) => void
): void {
    parent.addEventListener(eventType, function (event: Event) {
        const target = event.target as HTMLElement;

        // Check if the target or any of its ancestors have the className
        let currentElement: HTMLElement | null = target;
        while (currentElement) {
            if (!currentElement.classList) {
                console.log(currentElement);

            }
            if (currentElement.classList?.contains(className)) {

                callback(event, currentElement);
                break;
            }
            currentElement = currentElement.parentElement;
        }
    });
}
export { addEventDelegation };
