import axios from 'axios';
import handleErrors from './utils/errors';

import { hideLoader, showLoader } from './utils/loader';
import { addEventDelegation } from './utils/dom/event';
import { getAttributeOrThrow, getElementByIdOrThrow } from './utils/dom/getElementByIdOrThrow';
import { getCsrfToken } from './utils/form';
import { showErrorModal, showQuestionModal, showQuestionModalDanger } from './utils/alert';
import Swal from 'sweetalert2';
import { showToastError } from './utils/toast';


(() => {


    const themeToggleDarkIcon = document.getElementById("theme-toggle-dark-icon");
    const themeToggleLightIcon = document.getElementById("theme-toggle-light-icon");
    const themeToggleBtn = document.getElementById("theme-toggle");

    const currentTheme = localStorage.getItem("color-theme");
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

    const offlineBadge = getElementByIdOrThrow('offline-badge');
    // Initialize the theme based on previous settings or system preference
    let theme = currentTheme === "dark" || (!currentTheme && prefersDarkScheme) ? "dark" : "light";
    const currentUrl = window.location.pathname;
    const regex = new RegExp(`${currentUrl}$`);

    window.addEventListener('beforeunload', () => {

        showLoader();

        // Set a timer to hide the loader after 3000 milliseconds (adjust the time as needed)
        setTimeout(function () {
            hideLoader();
        }, 3000); // 3000 milliseconds = 3 seconds
    });

    // Show offline

    function showOfflineBadge() {
        offlineBadge.classList.remove('hidden');
    }

    function hideOfflineBadge() {

        offlineBadge.classList.remove("fade-in-right", "bg-red-600")
        offlineBadge.classList.add("bg-green-600", 'fade-out-left')
        offlineBadge.innerHTML = 'Online';

        setTimeout(function () {
            offlineBadge.classList.add("hidden", "fade-in-right", "bg-red-600")
            offlineBadge.classList.remove("bg-green-600", 'fade-out-left')

            offlineBadge.innerHTML = 'Offline';
        }, 5000);
    }

    window.addEventListener("online", function () {
        hideOfflineBadge();
    });

    window.addEventListener("offline", function () {
        showOfflineBadge();
    });

    if (navigator.onLine === false) {
        showOfflineBadge();
    }

    /*HIGHLIGHT SELECTED ITEM IN SIDEBAR */

    const elements = document.querySelectorAll<HTMLElement>(".highlightable");
    for (const element of elements) {
        const href = getAttributeOrThrow(element, "href");

        if (regex.test(href)) {
            element.classList.remove("text-gray-500");
            element.classList.add("bg-blue-700", "text-white", 'rounded-lg', 'shadow-lg');

            const svgElement = element.querySelector<SVGElement>("svg");
            svgElement!.classList.remove("text-gray-500");
            svgElement!.classList.add("text-white");

            // break;DONT BREAK AS THERE ARE TWO ELEMENTS ONE FOR MOBILE SIDEBAR AND OTHER FOR DESKTOP SIDEBAR

        }
    }


    addEventDelegation(document, 'click', 'notification', (event, matchedElement) => {

        const linkElement = matchedElement as HTMLLinkElement;
        event.preventDefault();
        const notificationUpdateUrl = getAttributeOrThrow(linkElement, 'data-url');

        axios
            .put(
                notificationUpdateUrl,
                {
                    is_read: true,
                },
                {
                    headers: {
                        "X-CSRFToken": getCsrfToken(),
                    },
                }
            )
            .then(function () {
                //Navigate to the page of action url to see the details
                window.location.href = linkElement.href;
            })
            .catch((error) => {
                handleErrors(error);
            });
    });


    /**DARKMODE FILES */

    // Set the appropriate icon and class on load
    if (theme === "dark") {
        themeToggleLightIcon?.classList.remove("hidden");
    } else {
        themeToggleDarkIcon?.classList.remove("hidden");
    }


    // Toggle the theme on button click
    themeToggleBtn?.addEventListener("click", () => {
        theme = theme === "light" ? "dark" : "light";
        localStorage.setItem("color-theme", theme);

        // Update icons and theme class
        themeToggleDarkIcon?.classList.toggle("hidden");
        themeToggleLightIcon?.classList.toggle("hidden");
        document.documentElement.classList.toggle("dark-class", theme === "light");
    });

    // notification toast js

    addEventDelegation(document, 'click', 'close-toast', (_event, matchedElement) => {
        const button = matchedElement as HTMLButtonElement;


        const alert = getElementByIdOrThrow(getAttributeOrThrow(button, 'data-dismiss-target-id'));

        alert.classList.remove('fade-in-down');
        alert.classList.add('fade-out-right');
        setTimeout(() => {
            alert.remove();

        }, 1000);
    })

    // TODO USE AN ANIMATION LIBRARY SUCH AS ANIMEJS OR GSAP TO ANIMATE MULTIPLE ALERTS
    window.addEventListener('load', () => {


        const element = document.getElementsByClassName('toast-message')[0];
        if (!element) {


            return
        }

        element.classList.remove('hidden');
        setTimeout(() => {
            element.classList.remove('fade-in-down');
            element.classList.add("fade-out-right");
            setTimeout(() => {
                element.remove();
            }, 1000);
        }, 5000);
    })


    addEventDelegation(document, 'click', 'show-logout-modal', (event) => {
        const title = "Sign Out";
        const text = `Are you sure you want to sign out?`;
        const confirmButtonText = "Yes,I'm sure.";
        const cancelButtonText = "No, Cancel";
        const logoutUrl = getAttributeOrThrow(getElementByIdOrThrow('csrf-token'), 'data-logout-url');
        const headers = {
            "X-CSRFToken": getCsrfToken(),
        };
        showQuestionModalDanger(title, text, confirmButtonText, cancelButtonText).then(
            (result) => {
                if (result.isConfirmed) {
                    axios.post(logoutUrl, {}, { headers }).then((response) => {
                        window.location.reload()

                    })


                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {

                    const errorMessage = "Sign out cancelled";
                    showToastError(errorMessage);
                }
            }
        );
    })




    document.getElementById('dropdownAvatarNameButton')?.addEventListener('click', () => {
        getElementByIdOrThrow('dropdownAvatarNameProfile').classList.toggle('hidden')
    })
})();