

function showLoader() {
    $("#loader-spinner").show(); // Show the loader
}

function hideLoader() {
    //   $("#blur-backdrop").hide(); // Show the backdrop
    $("#loader-spinner").hide(); // Show the loader
}


export { hideLoader, showLoader };